
<div class="operations-modal">
  <h3 class="modal-title text-center mb-5">{{ 'JOBS.JOB_UNPUBLISH_INFO_QUESTION' | translate}}</h3>
  <div class="operations">
    <button class="btn btn-outline-primary"
            (click)="closeModal()">{{ 'BUTTONS.CANCEL' | translate }}</button>
    <button class="btn btn-outline-primary"
            (click)="modifyPlatformInfo()">{{ 'JOBS.MODIFY_INFO' | translate }}</button>
    <button class="btn btn-primary"
            (click)="unpublish()">{{ 'SHARED.UNPUBLISH' | translate }}</button>
  </div>
</div>
