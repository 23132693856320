import { Component } from '@angular/core';
import { AbstractModal } from '../abstract-modal';
import { ModalService } from '../../../services/modal.service';
import { PlatformOperationModalData } from '../../../model/modal.interface';

@Component({
  selector: 'app-platform-operation-modal',
  templateUrl: './platform-operation-modal.component.html',
  styleUrls: ['./platform-operation-modal.component.scss']
})
export class PlatformOperationModalComponent extends AbstractModal {

  get data(): PlatformOperationModalData {
    return this.modal.data;
  }

  constructor(
    modalService: ModalService,
  ) {
    super(modalService);
  }

  unpublish(): void {
    this.data.unpublish();
    this.closeModal();
  }

  modifyPlatformInfo(): void {
    this.data.modifyPlatformInfo();
    this.closeModal();
  }
}
